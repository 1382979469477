import React, { Component, Suspense } from "react";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";

import feature1 from "../../assets/images/bid-mob-2.png";
import feature2 from "../../assets/images/bid-mob-3.png";
import feature3 from "../../assets/images/bid-mob-4.png";
import headImg from "../../assets/images/mob-banner.png";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const Banner = React.lazy(() => import("../common/Banner.component"));
const Feature = React.lazy(() => import("./services/Feature.component"));

class MobileMarketing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/services",
      features: [
        {
          key: 1,
          img: feature1,
          heading:
            "Maximize your reach through multichannel mobile marketing solutions          ",

          desc: "We cover a wide range of mobile marketing channels, including mobile web, in-app advertising, media buying, affiliate marketing, and direct app partnerships to reach the maximum number of customers.We focus on all the major mobile OS platforms, manufacturers, devices, and carriers, to ensure maximum reach and impact.Our expertise in fraud prevention and performance optimization ensures that marketing efforts are effective, efficient, and targeted to the right audience.",
          align: "right",
        },
        {
          key: 2,
          img: feature2,
          heading:
            "Make smarter ad optimization decisions based on accurate data",

          desc: "Measure and optimize your user acquisition campaigns and marketing performance, and also understand how in-app events further down the funnel influence their efforts with the integration of mobile attribution platforms.We integrate top mobile attributions such as Appsflyer, Branch, Adjust, Kochava, Singular, and Yandex Appmetrica to ensure accuracy and actionable insights to maximize your marketing efforts and drive better ROI.         ",
          align: "left",
        },
        {
          key: 3,
          img: feature3,
          heading:
            "Protect your investment from fraud and optimize performance",

          desc: `Build trust throughout the user journey. Stop fake accounts, payment fraud, ATOs, and more with fraud prevention by implementing advanced tools and strategies and boosting performance and ROI.Our team of experts uses various techniques such as IP blocking, device fingerprinting, and machine learning algorithms to protect your investment from fraudulent ones.Successful fraud prevention and performance optimization requires a data-driven approach, with a focus on continuous improvement and testing.We are dedicated to staying up to date with the latest trends and techniques to ensure that your campaigns are effective and efficient.
          `,
          align: "right",
        },
      ],
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            Harness the power of mobile marketing to engage and activate your
            potential customers
          </title>
          <meta
            name="description"
            content="Maximize ROI with Bidwave Media's Search Advertising Services. Enhance Online Visibility, Optimize Keywords, and Track Performance for Business Success."
          />
          <meta
            name="keywords"
            content="search ads, keyword optimization, online visibility, performance tracking, search advertising agency, "
          />
        </Helmet>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />
          <Banner
            titlePart1="Harness the power of mobile marketing to engage and activate your potential customers"
            headImg={headImg}
            desc="Connect with your potential customers instantly, irrespective of location, using modern mobile technology. "
          />

          <section className="section pt-3" id="features">
            <Container>
              {this.state.features.map((feature, key) => (
                <Feature details={feature} />
              ))}
            </Container>
          </section>

          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default MobileMarketing;
