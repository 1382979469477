import React, { Component, Suspense } from "react";
import { Helmet } from "react-helmet";

import CommonPubadv from "../pages/CommonPubadv";

import headImg from "../../assets/images/home-banner-bid.png";

const Header = React.lazy(() => import("../common/Header.component"));
const Footer = React.lazy(() => import("../common/Footer.component"));
const Banner = React.lazy(() => import("../common/Banner.component"));

const About = React.lazy(() => import("./home/About.component"));
const Counter = React.lazy(() => import("./home/Counter.component"));
const Clients = React.lazy(() => import("./home/Clients.component"));
const Features = React.lazy(() => import("./home/Features.component"));
const Services = React.lazy(() => import("./home/Services.component"));

class HomePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: "/home",
    };
  }

  Loader = () => {
    return (
      <div id="preloader">
        <div id="status">
          <div className="spinner">
            <div className="dot1"></div>
            <div className="dot2"></div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>
            The Best Digital Marketing Agency for Advertisers & Publishers -
            Bidwave Media
          </title>
          <meta
            name="description"
            content="Elevate Your Brand with Bidwave Media's Creative Digital Advertising Solutions. Drive Engagement, Conversions, and Success with Our Expert Marketing Services."
          />
          <meta
            name="keywords"
            content="digital advertising, marketing solutions, brand growth, online presence, creative excellence, best digital marketing agency, "
          />
        </Helmet>
        <Suspense fallback={this.Loader()}>
          <Header currentPage={this.state.currentPage} />
          <Banner
            titlePart1="Reach, Engage, and Convert Customers Anytime with SMS, Apps, Social Media, and Ads."
            // titlePart2="email"
            // titlePart3="campaigns."
            headImg={headImg}
          />
          <Services />
          <About />
          <Features />
          <Counter />
          <Clients />
          <CommonPubadv
            head="Want us to manage your campaigns?"
            para="Rely on our expertise to efficiently handle your campaigns.
          We ensure optimal management for superior results. Trust us
          with your success."
            btnname="Join Us Advertiser"
            btnlink="/advertiser"
          />
          <Footer />
        </Suspense>
      </React.Fragment>
    );
  }
}

export default HomePage;
