import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import HomePage from "./components/pages/HomePage";

import ContactPage from "./components/pages/ContactPage";

import EmailMarketingPage from "./components/pages/EmailMarketingPage";
import SearchAdvertisingPage from "./components/pages/SearchAdvertisingPage";
import SocialMediaMarketingPage from "./components/pages/SocialMediaMarketingPage";

import PrivacyPolicyPage from "./components/pages/PrivacyPolicyPage";
import TermsAndConditionsPage from "./components/pages/TermsAndConditionsPage";
import AdvertiserTermsAndConditions from "./components/pages/AdvertiserTermsAndConditions";
import AffiliateTermsAndConditions from "./components/pages/AffiliateTermsAndConditions";
import AboutUsPage from "./components/pages/AboutUsPage";
import AdvertiserPage from "./components/pages/AdvertiserPage";
import PublisherPage from "./components/pages/PublisherPage";
import BlogPage from "./components/pages/BlogPage";
import Traditionalmarketingvsdigitalmarketing from "./components/pages/Traditionalmarketingvsdigitalmarketing";
import Howtogetstartedwithperformancemarketing from "./components/pages/Howtogetstartedwithperformancemarketing";
import Contentmarketingstrategiestogrowyourbusiness from "./components/pages/Contentmarketingstrategiestogrowyourbusiness";
import Shorthandsmsmarketingstillhasitsleadoverlargemarginsglobally from "./components/pages/Shorthandsmsmarketingstillhasitsleadoverlargemarginsglobally";
import Catapultyourbrandsmarketingroithroughaffiliatemarketing from "./components/pages/Catapultyourbrandsmarketingroithroughaffiliatemarketing";
import Canyoumaximizeyourreachandsalesthroughvideomarketing from "./components/pages/Canyoumaximizeyourreachandsalesthroughvideomarketing";
import Howaiischangingthelandscapeofdigitalmarketing from "./components/pages/Howaiischangingthelandscapeofdigitalmarketing";
import Googleadsvsfacebookadsunfoldingthedichotomy from "./components/pages/Googleadsvsfacebookadsunfoldingthedichotomy";
import Emailmarketingtrendstoexpectthisyearandbeyond from "./components/pages/Emailmarketingtrendstoexpectthisyearandbeyond";
import Whatmistakesshouldyouavoidwhileadvertisingonfacebook from "./components/pages/Whatmistakesshouldyouavoidwhileadvertisingonfacebook";
import Customerexperiencethemostimportantmarketingtool from "./components/pages/Customerexperiencethemostimportantmarketingtool";
import PageNotFound from "./components/pages/PageNotFound";
import Mobilemarketing from "./components/pages/MobileMarketing";
// Import Scss
import "./theme.scss";

// Import Icon Css
import "./assets/css/materialdesignicons.min.css";
import "./assets/css/pe-icon-7.css";
import MobileMarketing from "./components/pages/MobileMarketing";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <React.Fragment>
        <Router>
          <Routes>
            {/* Redirect "/home" to "/" */}
            <Route path="/home" element={<Navigate to="/" />} />

            <Route path="/" element={<HomePage />} />
            <Route path="/about-us" element={<AboutUsPage />} />
            <Route path="/email-marketing" element={<EmailMarketingPage />} />
            <Route path="/mobile-marketing" element={<MobileMarketing />} />
            <Route
              path="/search-advertising"
              element={<SearchAdvertisingPage />}
            />
            <Route
              path="/social-media-marketing"
              element={<SocialMediaMarketingPage />}
            />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditionsPage />}
            />
            <Route path="/blog" element={<BlogPage />} />
            <Route
              path="/customer-experience-the-most-important-marketing-tool"
              element={<Customerexperiencethemostimportantmarketingtool />}
            />
            <Route
              path="/what-mistakes-should-you-avoid-while-advertising-on-facebook"
              element={<Whatmistakesshouldyouavoidwhileadvertisingonfacebook />}
            />
            <Route
              path="/email-marketing-trends-to-expect-this-year-and-beyond"
              element={<Emailmarketingtrendstoexpectthisyearandbeyond />}
            />
            <Route
              path="/google-ads-vs-facebook-ads-unfolding-the-dichotomy"
              element={<Googleadsvsfacebookadsunfoldingthedichotomy />}
            />
            <Route
              path="/how-ai-is-changing-the-landscape-of-digital-marketing"
              element={<Howaiischangingthelandscapeofdigitalmarketing />}
            />
            <Route
              path="/can-you-maximize-your-reach-and-sales-through-video-marketing"
              element={<Canyoumaximizeyourreachandsalesthroughvideomarketing />}
            />
            <Route
              path="/catapult-your-brands-marketing-roi-through-affiliate-marketing"
              element={
                <Catapultyourbrandsmarketingroithroughaffiliatemarketing />
              }
            />
            <Route
              path="/how-to-get-started-with-performance-marketing"
              element={<Howtogetstartedwithperformancemarketing />}
            />
            <Route
              path="/content-marketing-strategies-to-grow-your-business"
              element={<Contentmarketingstrategiestogrowyourbusiness />}
            />
            <Route
              path="/short-hand-sms-marketing-still-has-its-lead-over-large-margins-globally"
              element={
                <Shorthandsmsmarketingstillhasitsleadoverlargemarginsglobally />
              }
            />

            <Route
              path="/traditional-marketing-vs-digital-marketing"
              element={<Traditionalmarketingvsdigitalmarketing />}
            />
            <Route path="/advertiser" element={<AdvertiserPage />} />
            <Route path="/publisher" element={<PublisherPage />} />
            <Route
              path="/advertiser-terms-and-conditions"
              element={<AdvertiserTermsAndConditions />}
            />
            <Route
              path="/affiliate-terms-and-conditions"
              element={<AffiliateTermsAndConditions />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/contact-us" element={<ContactPage />} />

            <Route path="*" element={<PageNotFound />} />
          </Routes>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
